import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Popper from '@mui/material/Popper';
import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme } from '@mui/material';

type TMenuOption = {
  children?: any;
  textColor?: string;
  colorBg?: string;
  padding?: string;
  toggleAside: () => void;
  closeAllExpansion?: any;
  title?: string;
  titlePopper?: string;
  principalMargin?: string;
  isOpen?: boolean;
  disabled?: boolean;
  icon?: any | ReactNode;
  closeExpansions?: boolean;
};
export const MenuOptionV: React.FC<TMenuOption> = ({
  children,
  textColor,
  colorBg,
  padding,
  closeAllExpansion,
  toggleAside,
  title,
  principalMargin,
  titlePopper,
  isOpen,
  closeExpansions,
  // tooltip,
  icon,
  disabled,
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const [openPopper, setOpenPopper] = useState(false);
  const handleMouseEnter = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper(true);
  };

  const handleMouseLeave = () => {
    setOpenPopper(false);
  };

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    if (!isOpen) {
      toggleAside();
    }
  };

  const styles = {
    subTitle: {
      fontWeight: '400',
      fontSize: '0.8em',
      margin: isOpen ? '7px 0 0 10px' : '0',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '100%',
      color: textColor || theme.palette.primary.main,
    },
    subTitlePopper: {
      fontWeight: 'bold',
      fontSize: '0.8em',
      margin: isOpen ? '7px 0 0 10px' : '0',
      textAlign: 'left',
      width: '100%',
      color: theme.palette.primary.main,
    },
  };

  useEffect(() => {
    if (closeExpansions) {
      setExpanded(false);
      closeAllExpansion(false);
    }
  }, [closeExpansions]);

  return (
    <Accordion
      expanded={expanded === 'panel1' && isOpen}
      onChange={handleChange('panel1')}
      disableGutters
      sx={{
        margin: principalMargin || '0 0 0 0',
        width: '100%',
        background: colorBg || '#fff',
        borderRadius: '0px',
        // margin: !isOpen ? '6px' : 0
      }}
      onMouseEnter={(e) => handleMouseEnter(e)}
      onMouseLeave={handleMouseLeave}>
      <AccordionSummary
        // expandIcon={isOpen && <ExpandMoreIcon sx={{ color: textColor }} />}
        disabled={disabled}
        aria-controls="panel1bh-content"
        sx={{ height: '40px', minHeight: '25px', padding: isOpen ? '0px 10px 0' : '0 16px' }}
        id="panel1bh-header">
        {!isOpen && (
          <Popper
            sx={{
              backgroundColor: theme.palette.primary.light,
              height: '40px',
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
              padding: '10px 5px',
              zIndex: 100,
              alignContent: 'center',
            }}
            open={openPopper}
            anchorEl={anchorEl}
            placement="right">
            <Typography sx={styles.subTitlePopper}>{titlePopper}</Typography>
          </Popper>
        )}
        <div style={{ marginLeft: !isOpen ? '6px' : 0 }}>{icon}</div>
        <Typography sx={styles.subTitle}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: padding || '0' }}>{children}</AccordionDetails>
    </Accordion>
  );
};
