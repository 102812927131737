import React, { Suspense, useEffect, useState } from 'react';
import { CssBaseline } from '@mui/material';
import Auth from './core/infrastructure/apollo/auth';
import Loading from './core/infrastructure/common/loading';
import Routes from './core/presentation/routes';
import './assets/css/scrollbar.css';
import { selectClientName } from './common/utils';
import { NoAuthorizeClient } from './core/infrastructure/common/noAuthorize/noAuthorizeClient';

const App = () => {
  const client = selectClientName();
  const [state, setState] = useState<any>();

  useEffect(() => {
    fetch(`https://manager.wowdesarrollos.com/api/security/validateLicense/${client}`, {
      headers: {
        token: 'MILI',
        'api-key': '004b7ce8a5cb0e30c6666365fd0174fa2a44f6d358dcfc93e7a8d420e1965a8e',
      },
    })
      .then((res) => res.json())

      .then((data) => {
        setState(data);
      });
  }, []);

  return (
    <>
      {state?.access === false && client !== 'local' && client !== 'pgqa' ? (
        <NoAuthorizeClient />
      ) : (
        <div className="App">
          <Suspense fallback={<Loading />}>
            <CssBaseline />
            <Auth>
              {(logged: any, isMount: any) => {
                if (isMount) {
                  return <Routes isLogged={logged.state} />;
                }
                return <Loading />;
              }}
            </Auth>
          </Suspense>
        </div>
      )}
    </>
  );
};

export default App;
