import React, { FC, useEffect, useState } from 'react';
import { Tooltip, Typography, useTheme } from '@mui/material';
import WidgetsIcon from '@mui/icons-material/Widgets';
import styled from 'styled-components';
import { NewHeader } from './Header';
import { NewAsideV } from './Aside';
import { TLoggedUser, TUserDataLogged } from './types.d';
// import { dataMenus } from './menuData';
import { newDataMenus } from './newMenuData';
import { SecondAsideTypeV } from './SecondAsideType';
import pkg from '../../../../../package.json';

type TLayout = {
  layoutType?: number;
};
// componente de styled component contenedor para el aside y para el header del layout
export const LayoutContainer = styled.div<TLayout>`
  display: grid;
  grid-template-columns: 1fr 32fr;
  grid-template-rows: 1fr 18fr;
  grid-template-areas: ${({ layoutType }) =>
    layoutType === 1
      ? `"header header"
     "aside main"`
      : `"aside header"
     "aside main"`};
  height: 100vh;
`;

type TAside = {
  isOpen?: boolean;
  widthCollapsed?: string;
  widthExpanded?: string;
  bgColor?: string;
  gradientAside?: boolean;
  gradientHeader?: boolean;
};
// componente de styled component para el aside del layout
export const Aside = styled.aside<TAside>`
  grid-area: aside;
  color: #fff;
  display: flex;
  /* width: 280px; */
  width: ${({ isOpen, widthCollapsed, widthExpanded }) =>
    isOpen ? widthExpanded : widthCollapsed};
  overflow: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* z-index: 1700; */
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(0px)')};
  transition:
    transform 0.1s ease-in-out,
    width 0.3s ease-in-out; /* Agrega una transición suave al transform y la anchura */
`;

// componente de styled component para el main del layout
export const Main = styled.main`
  grid-area: main;
  background-color: #dcdcdc;
  color: #000;
  display: flex;
  overflow: auto;
  padding: 20px 0;
  /* justify-content: center;
    align-items: center; */
`;

// componente de styled component para la cabecera fija del aside
export const HeaderAside = styled.div<{ bgColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 86px;
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};
  color: #fff;
  border-bottom: 1px solid ${({ bgColor }) => bgColor};
  /* z-index: 1700; */
`;

// componente de styled component para el footer del aside
export const FooterAside = styled.div<{ bgColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};
  color: #fff;
  border-top: 1px ${({ bgColor }) => bgColor};
`;

type TButtonFloating = {
  isOpen?: boolean;
};
// componente de styled component que es un botón fijo flotante en la parte inferior izquierda pero solo en el componente Main sin estar sobre el aside
export const ButtonFloating = styled.button<TButtonFloating>`
  position: fixed;
  bottom: 10px;
  z-index: 1600;
  padding: 2px;
  left: ${({ isOpen }) => (isOpen ? '280px' : '90px')};
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  transition: all 0.1s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;

const userDataInfo = {
  idUserInfinito: 0,
  name: '',
  password: '',
};

export const NewLayoutComponent: FC<any> = ({
  handleSession,
  dataMenu,
  loadingMenu,
  children,
  control,
  schema,
  handleUpdatePassword,
  handleSubmit,
}) => {
  const theme = useTheme();
  // const colorLocalStorage = window.localStorage.getItem('colorsTheme');
  // const colorStorage = colorLocalStorage ? JSON.parse(colorLocalStorage) : theme;

  const [isOpen, setIsOpen] = useState(true);
  const [userInfinito, setUserInfinito] = useState<TUserDataLogged>(userDataInfo);
  const [loggedUser, setLoggedUser] = useState<TLoggedUser>(userDataInfo);
  // estado para el tipo de layout
  const [layoutType] = useState(1);
  // estado para el tipo de header
  const [headerType] = useState(3);
  // estado para el tipo de aside
  const [asideType] = useState(1);
  // estado para habilitar el gradiente del aside
  const [gradientAside] = useState(true);
  // const [gradientAside] = useState(colorStorage?.gradients?.headerGradient);
  // estado para habilitar el gradiente del header
  const [gradientHeader] = useState(true);
  // const [gradientHeader] = useState(colorStorage?.gradients?.modulesGradient);
  // estado para uso de color en el header o no
  const [colorHeader] = useState(true);
  // instancia del hook useTheme de material ui
  window.localStorage.setItem('isOpenAside', JSON.stringify(true));

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [closeExpansions, setCloseExpansions] = useState(false);

  const handleDrawerOpen = (state?: boolean) => {
    if (!drawerOpen) setIsOpen(true);
    setDrawerOpen(state || !drawerOpen);
  };
  const CloseAllExpansion = (state?: boolean) => {
    setCloseExpansions(state || !closeExpansions);
  };

  const layoutStyles = {
    footerText: {
      fontSize: '11px',
      padding: '5px',
      width: '100%',
      color: '#fff',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    drawerStyle: {
      width: '245px',
      flexShrink: 0,
      left: '245px',
      '& .MuiDrawer-paper': {
        left: '245px',
        width: '245px',
        top: '80px',
        zIndex: 2,
        backgroundColor: '#fff',
      },
    },
  };

  const toggleAside = () => {
    if (isOpen) setDrawerOpen(false);
    setIsOpen(!isOpen);
    // guardar campo isOpen en localstorage
    window.localStorage.setItem('isOpenAside', JSON.stringify(!isOpen));
  };

  useEffect(() => {
    setUserInfinito({
      name: 'user',
      password: '123123',
    });
    setLoggedUser({
      idUser: 1,
      name: 'admin',
      lastName: 'admin',
      email: 'admin@afianza.com',
      phone: '3020202',
    });
  }, []);

  return (
    <LayoutContainer layoutType={layoutType}>
      <NewHeader
        isOpen={isOpen}
        toggleAside={toggleAside}
        loggedUser={loggedUser}
        closeAllExpansion={CloseAllExpansion}
        headerType={headerType}
        gradientHeader={gradientHeader}
        colorHeader={colorHeader}
        handleSession={handleSession}
        control={control}
        schema={schema}
        handleSubmit={handleSubmit}
        handleUpdatePassword={handleUpdatePassword}
      />
      <Aside
        isOpen={isOpen}
        widthCollapsed="80px" /* Anchura del aside cuando está oculto */
        widthExpanded="245px" /* Anchura del aside cuando está visible */
        gradientAside={gradientAside}
        gradientHeader={gradientHeader}>
        {layoutType !== 1 && <HeaderAside bgColor={theme.palette.primary.dark} />}
        {asideType === 1 ? (
          <NewAsideV
            drawerOpen={drawerOpen}
            toggleAside={toggleAside}
            handleDrawerOpen={handleDrawerOpen}
            isOpen={isOpen}
            data={dataMenu}
            loadingMenu={loadingMenu}
            closeAllExpansion={CloseAllExpansion}
            closeExpansions={closeExpansions}
            layoutType={layoutType}
            headerType={headerType}
            userInfo={userInfinito}
            gradientAside={gradientAside}
            gradientHeader={gradientHeader}
          />
        ) : (
          <SecondAsideTypeV
            drawerOpen={drawerOpen}
            toggleAside={toggleAside}
            handleDrawerOpen={handleDrawerOpen}
            isOpen={isOpen}
            data={newDataMenus}
            loadingMenu={loadingMenu}
            closeAllExpansion={CloseAllExpansion}
            closeExpansions={closeExpansions}
            layoutType={layoutType}
            headerType={headerType}
            userInfo={userInfinito}
            gradientAside={gradientAside}
            gradientHeader={gradientHeader}
          />
        )}
        {/* {layoutType !== 1 && ( */}
        <FooterAside bgColor={theme.palette.primary.main}>
          <Typography sx={layoutStyles.footerText}>
            {isOpen ? '® WOW Desarrollos Digitales - Mili' : ''} {pkg.version}
          </Typography>
        </FooterAside>
        {/* )} */}
      </Aside>
      <Main>
        {children}
        {asideType === 2 ? (
          <Tooltip title="Ver cinta de opciones del sub modulo actual" placement="right">
            <ButtonFloating isOpen={isOpen} onClick={() => handleDrawerOpen()}>
              <WidgetsIcon fontSize="medium" sx={{ margin: '2px 0 0 2px' }} color="primary" />
            </ButtonFloating>
          </Tooltip>
        ) : null}
      </Main>
    </LayoutContainer>
  );
};
