/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Drawer, Skeleton, Typography, useTheme } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { TNewAside } from './types.d';
import { MenuOptionV } from './MenuOption';
import { dataMenus } from './menuData';

type TAside = {
  width?: string;
  height?: string;
  bgColor?: string;
  isOpen?: boolean;
  widthCollapsed?: string;
  widthExpanded?: string;
  marginTop: string;
};

const Aside = styled.aside<TAside>`
  background: ${({ bgColor }) => bgColor || '#eee'};
  width: ${({ isOpen, widthCollapsed, widthExpanded }) =>
    isOpen ? widthExpanded : widthCollapsed};
  height: 100%;
  padding-top: 2px;
  margin-top: ${({ marginTop }) => marginTop || '0px'};
  /* overflow-y: scroll; */
  overflow: auto;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(0px)')};
  transition:
    transform 0.3s ease-in-out,
    width 0.3s ease-in-out; /* Agrega una transición suave al transform y la anchura */
`;

type TMenuOptionLink = {
  width?: string;
  height?: string;
  bgColor?: string;
  isOpen?: boolean;
  widthCollapsed?: string;
  widthExpanded?: string;
};

const MenuOptionLink = styled.div<TMenuOptionLink>`
  width: ${({ isOpen, widthCollapsed, widthExpanded }) =>
    isOpen ? widthExpanded : widthCollapsed};
  /* height: 37px; */
  background: ${({ bgColor }) => bgColor || '#fff'};
  padding: 10px 15px 10px 25px;
  :hover {
    cursor: pointer;
    background: ${({ bgColor }) => bgColor || '#eee'};
  }
`;

// componente footer del aside
// const Footer = styled.div`
//   width: 100%;
//   height: 50px;
//   background: #fff;
//   position: absolute;
//   bottom: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

export const NewAsideV: React.FC<TNewAside> = ({
  isOpen,
  headerType,
  drawerOpen,
  toggleAside,
  handleDrawerOpen,
  gradientAside,
  loadingMenu,
  layoutType,
  closeExpansions,
  closeAllExpansion,
}: TNewAside) => {
  const theme = useTheme();

  const styles = {
    subTitle: {
      fontSize: '0.8em',
      textAlign: 'left',
      width: '100%',
      color: theme.palette.primary.main,
    },
    subTitleModule: {
      fontWeight: 'regular',
      fontSize: '0.8em',
      textAlign: 'left',
      width: '100%',
      color: '#fff',
    },
    icon: {
      fontSize: isOpen ? '20px' : '24px',
      color: '#fff',
      marginTop: '6px',
    },
    iconDot: {
      fontSize: '14px',
      color: '#fff',
      marginTop: '5px',
    },
  };

  return (
    <Aside
      isOpen={isOpen}
      marginTop={headerType === 3 && layoutType !== 2 ? '15px' : '0px'}
      widthCollapsed="80px" /* Anchura del aside cuando está oculto */
      widthExpanded="245px" /* Anchura del aside cuando está visible */
      bgColor={
        gradientAside
          ? `linear-gradient(150deg, ${theme.palette.primary.dark}  0%, ${theme.palette.primary.dark}  45%, ${theme.palette.primary.main} 96%)`
          : theme.palette.primary.dark
      }>
      <div
        style={{
          display: 'flex',
          padding: '3px',
          flexDirection: 'column',
        }}>
        {!loadingMenu && dataMenus?.length
          ? dataMenus?.map((x: any, indexX: number) => {
              const key = `keyX-${indexX}`;
              const { subMenus } = x;
              return (
                <MenuOptionV
                  key={key}
                  disabled={
                    x?.mName === 'COMERCIALES' ||
                    x?.mName === 'PARÁMETROS' ||
                    x?.mName === 'CARTERA' ||
                    x?.mName === 'BENEFICIARIOS' ||
                    x?.mName === 'USUARIOS'
                  }
                  closeExpansions={closeExpansions}
                  closeAllExpansion={closeAllExpansion}
                  toggleAside={toggleAside}
                  colorBg={
                    gradientAside
                      ? `linear-gradient(150deg, ${theme.palette.primary.dark}  0%, ${theme.palette.primary.main}  45%, ${theme.palette.primary.main} 96%)`
                      : theme.palette.primary.main
                  }
                  textColor="#fff"
                  title={isOpen ? x?.name : ''}
                  titlePopper={x?.name}
                  isOpen={isOpen}
                  icon={
                    (x?.name === 'PAGADURÍAS' && <BusinessIcon style={styles.icon} />) ||
                    (x?.name === 'INVERSIONISTAS' && <CardMembershipIcon style={styles.icon} />) ||
                    (x?.name === 'CRÉDITOS' && <RequestQuoteIcon style={styles.icon} />)
                  }>
                  {subMenus?.map((mod) => {
                    const moKey = `${mod}_${indexX}`;
                    return (
                      <MenuOptionV
                        key={moKey}
                        isOpen={isOpen}
                        closeExpansions={closeExpansions}
                        closeAllExpansion={closeAllExpansion}
                        toggleAside={toggleAside}
                        colorBg={theme.palette.primary.light}
                        textColor={theme.palette.primary.main}
                        title={mod?.name}
                        principalMargin="0">
                        {mod?.options?.length
                          ? mod?.options?.map((sm, ism) => {
                              const smKey = `${mod}_${ism}`;
                              return (
                                <Link
                                  to={sm.route}
                                  style={{
                                    textDecoration: 'none',
                                  }}
                                  key={smKey}>
                                  <MenuOptionLink>
                                    <Typography sx={styles.subTitle}>{sm?.name}</Typography>
                                  </MenuOptionLink>
                                </Link>
                              );
                            })
                          : null}
                        {/**
                            <Link
                          to="1"
                          style={{
                            textDecoration: 'none',
                          }}>
                          <MenuOptionLink>
                            <Typography sx={styles.subTitle}>CREDITOS</Typography>
                          </MenuOptionLink>
                        </Link>
                           */}
                      </MenuOptionV>
                    );
                  })}
                </MenuOptionV>
              );
            })
          : null}
        {loadingMenu
          ? [...Array(9)].map((_x, i) => (
              <Skeleton
                key={`skeleton-${i * 2}`}
                variant="rectangular"
                width="100%"
                height="40px"
                sx={{ backgroundColor: theme.palette.primary.main }}
              />
            ))
          : null}
      </div>
      <Drawer
        open={drawerOpen}
        onClose={() => handleDrawerOpen()}
        sx={{
          width: '270px',
          flexShrink: 0,
          left: '270px',
          '& .MuiDrawer-paper': {
            left: '270px',
            width: '270px',
            top: '80px',
            zIndex: 2,
            backgroundColor: '#fff',
          },
        }}>
        as
      </Drawer>
    </Aside>
  );
};
