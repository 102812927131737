import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LayoutC from '../containers/Layout';

/**
 * Pages
 */

// const Home = React.lazy(() => import('../pages/Home'));
const Login = React.lazy(() => import('../pages/login'));
const Test2 = React.lazy(() => import('../pages/test/pdf2'));
const Test = React.lazy(() => import('../pages/test/pdfs'));
const Customization = React.lazy(() => import('../pages/customization'));
const CreditsList = React.lazy(() => import('../pages/credits/creditsList'));
const Cirenio = React.lazy(() => import('../pages/test/cirenio'));
const HandleDocsNogue = React.lazy(() => import('../pages/test/handleDocs'));
const IntegrationResponseAdo = React.lazy(() => import('../pages/integrationResponseAdo'));
const PhaseCards = React.lazy(() => import('../pages/phases/phaseCard'));
const PhaseListCredits = React.lazy(() => import('../pages/phases/phaseListCredits'));
const Request = React.lazy(() => import('../pages/phases/request'));
const SetPasswordP = React.lazy(() => import('../pages/setPassword'));
const RecoverPasswordP = React.lazy(() => import('../pages/recoverPass'));
const EditCreditP = React.lazy(() => import('../pages/phases/editCredit'));

interface ProtectedRouteProps {
  page: React.ComponentType<any>;
  isLogged: boolean;
  path: string;
}

const ProtectedRoute = ({ isLogged, page: Page, path }: ProtectedRouteProps) => {
  if (!isLogged) {
    return <Login />;
  }

  return (
    <LayoutC path={path}>
      <Page />
    </LayoutC>
  );
};

const AllRoutes = ({ isLogged }: { isLogged: boolean }) => {
  return (
    <Routes>
      <Route index element={<ProtectedRoute path="/" page={PhaseCards} isLogged={isLogged} />} />
      <Route
        path="/personalizarColores"
        element={
          <ProtectedRoute path="/personalizarColores" page={Customization} isLogged={isLogged} />
        }
      />
      <Route
        path="/creditos"
        element={<ProtectedRoute path="/creditos" page={CreditsList} isLogged={isLogged} />}
      />
      <Route
        path="/creditos/detalle/:cId"
        element={<ProtectedRoute path="/creditos" page={Request} isLogged={isLogged} />}
      />
      <Route
        path="/phases"
        element={<ProtectedRoute path="/phases" page={PhaseCards} isLogged={isLogged} />}
      />
      <Route
        path="/phases/:pId"
        element={<ProtectedRoute path="/phases" page={PhaseListCredits} isLogged={isLogged} />}
      />
      <Route
        path="/credits/request/:cId"
        element={<ProtectedRoute path="/phases" page={Request} isLogged={isLogged} />}
      />
      <Route
        path="/credits/update/:cId"
        element={<ProtectedRoute path="/phases" page={EditCreditP} isLogged={isLogged} />}
      />
      <Route path="/pdfCriers" element={<Test2 />} />
      <Route path="/cirenio" element={<Cirenio />} />
      {/* Rutas Sin Protección */}
      <Route path="/test" element={<Test />} />
      <Route path="/nogue" element={<HandleDocsNogue />} />
      <Route path="/integrationAdo" element={<IntegrationResponseAdo />} />
      <Route path="/welcome/user/pass/:uId/:uToken" element={<SetPasswordP />} />
      <Route path="/recoverPassword" element={<RecoverPasswordP />} />
    </Routes>
  );
};

export default AllRoutes;
