import React from 'react';
import { Avatar, Box, Popper, Stack, Typography, useTheme } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
// import SettingsIcon from '@mui/icons-material/Settings';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import { TransitionsModal } from '../../../infrastructure/common/modal';
import useWindowDimensions from '../../../infrastructure/utils/hooks/useDimensions';
import { ChangePasswordV } from './changePassword';

export const DropdownHeaderV: React.FC<any> = ({
  open,
  handleMouseEnter,
  anchorEl,
  handleMouseLeave,
  stringAvatar,
  headerType,
  handleSession,
  sendConfirm,
  openModal,
  control,
  schema,
  handleSubmit,
  handleUpdatePassword,
  loadingUpdatePassword,
}) => {
  const theme = useTheme();
  const { h } = useWindowDimensions();
  const company = JSON.parse(localStorage.getItem('company') || '{}');
  const ucId = localStorage.getItem('ucId');

  const getUserType = () => {
    if (ucId === '3') return 'Administrador';
    if (ucId === '4') return 'Analista';
    if (ucId === '7') return 'Empresa';
    return null;
  };

  const style = {
    option: {
      display: 'flex',
      borderRadius: '5px',
      padding: '5px 15px',
      ':hover': {
        cursor: 'pointer',
        fontWeight: 'bold',
        backgroundColor: theme.palette.primary.light,
      },
    },
    icon: {
      fontSize: 'large',
      marginRight: '5px',
    },
    divBanner: {
      borderBottom: '1px solid #dedede',
      width: '90%',
      alignSelf: 'center',
      marginTop: '3px',
    },
    textOption: {
      fontSize: '0.8em',
    },
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <TransitionsModal
        state={openModal?.changePassword}
        overflow="auto"
        handleCloseModal={() => {
          sendConfirm('changePassword');
        }}
        width="40%"
        height={h(30)}>
        <ChangePasswordV
          control={control}
          schema={schema}
          handleSubmit={handleSubmit}
          handleUpdatePassword={handleUpdatePassword}
          loadingUpdatePassword={loadingUpdatePassword}
        />
      </TransitionsModal>
      <Popper
        sx={{
          backgroundColor: '#fff',
          height: h(30),
          width: '200px',
          boxShadow: '0px 0px 5px 0px #dcdcdc',
          borderRadius: '5px',
          padding: '5px 10px',
          alignContent: 'center',
          top: '60px',
          zIndex: 160016009,
        }}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '70%',
          }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" spacing={2} sx={{ padding: '10px' }}>
              <Avatar {...stringAvatar(`${company?.cBusNam?.toUpperCase() || ''} `)} />
              <div>
                <Typography sx={{ fontSize: '0.8em' /* , lineHeight: '0.7', marginTop: '12px' */ }}>
                  {company?.cBusNam?.toUpperCase()}
                </Typography>
                <Typography sx={{ fontSize: '0.8em' }}>{getUserType()?.toUpperCase()}</Typography>
              </div>
            </Stack>
            <div style={style.divBanner} />
          </div>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {/* <Box sx={style.option}>
              <AccountCircleIcon sx={style.icon} />
              <Typography sx={style.textOption}>Ver perfil</Typography>
            </Box> */}
            <Box sx={style.option} onClick={() => sendConfirm('changePassword')}>
              <VpnKeyIcon sx={style.icon} />
              <Typography sx={style.textOption}>Cambiar contraseña</Typography>
            </Box>
            <Link
              to="https://soporte.wowdesarrollos.com/"
              style={{ textDecoration: 'none', color: '#000' }}>
              <Box sx={style.option}>
                <HelpIcon sx={style.icon} />
                <Typography sx={style.textOption}>Soporte</Typography>
              </Box>
            </Link>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ ...style.divBanner, marginBottom: '5px', marginTop: '0' }} />
            {/* <Box sx={style.option}>
              <SettingsIcon sx={style.icon} />
              <Typography sx={style.textOption}>Opciones</Typography>
            </Box> */}
            <Box sx={style.option} onClick={handleSession}>
              <LogoutIcon sx={style.icon} />
              <Typography sx={style.textOption}>Cerrar sesión</Typography>
            </Box>
          </Box>
        </div>
      </Popper>
      <IconButton
        color={headerType === 1 ? 'inherit' : 'primary'}
        onMouseEnter={(e) => handleMouseEnter(e)}>
        {/* <PowerSettingsNewIcon /> */}
        <Stack direction="row" spacing={1}>
          <Avatar {...stringAvatar(`${company?.cBusNam?.toUpperCase() || ''}`, 'header')} />
        </Stack>
      </IconButton>
    </div>
  );
};
