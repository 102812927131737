export type TBrandsName =
  | 'local'
  | 'zali'
  | 'pgqa'
  | 'mili'
  | 'finnvo'
  | 'bancaliv'
  | 'creditoo'
  | 'inversiones'
  | 'fie'
  | 'fibanca'
  | 'fondo'
  | 'tusolucion'
  | 'credipress'
  | 'liberate'
  | 'nauw'
  | 'lenddi'
  | 'credinver'
  | 'freend'
  | 'gym'
  | 'maxcredit'
  | 'sofi'
  | 'libramoneda'
  | 'finanzas'
  | 'orquidea';

export const selectClientName: () => TBrandsName = () => {
  const client = window.location.hostname;
  if (client.includes('localhost')) {
    return 'local';
  }
  if (client.includes('inversiones')) {
    return 'inversiones';
  }
  if (client.includes('orquidea')) {
    return 'orquidea';
  }
  if (client.includes('zali')) {
    return 'zali';
  }
  if (client.includes('pgqa')) {
    return 'pgqa';
  }
  if (client.includes('mili')) {
    return 'mili';
  }
  if (client.includes('finnvo')) {
    return 'finnvo';
  }
  if (client.includes('bancaliv')) {
    return 'bancaliv';
  }
  if (client.includes('creditoo')) {
    return 'creditoo';
  }
  if (client.includes('fie')) {
    return 'fie';
  }
  if (client.includes('fibanca')) {
    return 'fibanca';
  }
  if (client.includes('elbancodelagente')) {
    return 'fondo';
  }
  if (client.includes('tusolucion')) {
    return 'tusolucion';
  }
  if (client.includes('credipress')) {
    return 'credipress';
  }
  if (client.includes('liberate')) {
    return 'liberate';
  }
  if (client.includes('nauwcolombia')) {
    return 'nauw';
  }
  if (client.includes('lenddi')) {
    return 'lenddi';
  }
  if (client.includes('credinver')) {
    return 'credinver';
  }
  if (client.includes('freend')) {
    return 'freend';
  }
  if (client.includes('gmserviciosfinancieros')) {
    return 'gym';
  }
  if (client.includes('maxcredit')) {
    return 'maxcredit';
  }
  if (client.includes('sofi')) {
    return 'sofi';
  }
  if (client.includes('libramoneda')) {
    return 'libramoneda';
  }
  if (client.includes('finanzas')) {
    return 'finanzas';
  }
  return 'mili';
};

/* const titles = {
  bancaliv: 'Bancaliv - PG',
  credipress: 'Credipress - PG',
  creditoo: 'Creditoo - PG',
  fibanca: 'Fibanca - PG',
  finnvo: 'Finnvo - PG',
  fondo: 'Fondo - PG',
  tusolucion: 'Tu Solución - PG',
  mili: 'Mili - PG',
  pgqa: 'Mili QA - PG',
  local: 'Mili - PG',
  liberate: 'Liberatech - PG',
  nauw: 'Nauw - PG',
  fie: 'Fie - PG',
  lenddi: 'Lenddi - PG',
  gym: 'G&M - PG',
  inversiones: 'Inversiones - PG',
  credinver: 'Credinver - PG',
  freend: 'Freend - PG',
};

const favicons = {
  bancaliv: '/favicon/bancaliv/favicon.ico',
  credipress: '/favicon/credipress/favicon.ico',
  creditoo: '/favicon/creditoo/favicon.ico',
  fibanca: '/favicon/fibanca/favicon.ico',
  finnvo: '/favicon/finnvo/favicon.ico',
  fondo: '/favicon/fondoGente/favicon.ico',
  tusolucion: '/favicon/tusolucion/favicon.ico',
  local: '/favicon/mili/favicon.ico',
  mili: '/favicon/mili/favicon.ico',
  pgqa: '/favicon/mili/favicon.ico',
  liberate: '/favicon/liberate/favicon.ico',
  nauw: '/favicon/nauw/favicon.ico',
  fie: '/favicon/fie/favicon.ico',
  lenddi: '/favicon/lenddi/favicon.ico',
  gym: '/favicon/gym/favicon.ico',
  credinver: '/favicon/credinver/favicon.ico',
  inversiones: '/favicon/inversiones/favicon.ico',
  freend: '/favicon/freend/favicon.ico',
};

const brands = {
  bancaliv: 'Bancaliv',
  credipress: 'Credipress',
  creditoo: 'Creditoo',
  fibanca: 'Fibanca',
  finnvo: 'Finnvo',
  fondo: 'Fondo',
  mili: 'Mili',
  pgqa: 'Mili QA',
  local: 'Mili',
  tusolucion: 'Tu Solución',
  liberate: 'Liberate',
  nauw: 'Nauw',
  fie: 'Fie',
  lenddi: 'Lenddi',
  gym: 'G&M',
  credinver: 'Credinver',
  inversiones: 'Inversiones & negocios',
  freend: 'freend',
};

const descriptions = {
  mili: 'Mili, tu crédito de confianza',
  pgqa: 'Mili QA, tu crédito de confianza',
  local: 'Mili, tu crédito de confianza',
  bancaliv: 'Bancaliv, Pensando en ti',
  credipress: 'Credipress, tu crédito 100% digital',
  finnvo: 'Finnvo, tu crédito de libranza',
  fibanca: 'Fibanca, Financiamiento de banca',
  fondo: 'Fondo de la gente',
  creditoo: 'Creditoo, Fábrica de crédito SAS',
  tusolucion: 'Tu solución, avanzamos contigo',
  liberate: 'Liberateco',
  nauw: 'Nauw, Siempre contigo',
  fie: 'Fie, Financiamos tus sueños',
  lenddi: 'Lenddi, Luz verde para tu crédito',
  gym: 'G&M, Servicios financieros S.A.S',
  credinver: 'Credinver',
  inversiones: 'Inversiones & negocios S.A',
  freend: 'Freend S.A',
};

export const setTitleIcon = (cli?: TBrandsName) => {
  const client = cli ?? selectClientName();
  const title = client ? titles[client] : titles.mili;
  const favico = client ? favicons[client] : favicons.mili;
  const brand = client ? brands[client] : brands.mili;
  const description = client ? descriptions[client] : descriptions.mili;
  const titleElement = document.querySelector('title') as any;
  titleElement.innerHTML = `${title}`;

  const favicon = document.querySelector("link[rel~='icon']") as any;
  favicon.href = favico;

  const brandExist = document.getElementById('brandExist');
  brandExist?.setAttribute('content', brand);

  const descriptionMeta = document.getElementById('descriptionMeta');
  descriptionMeta?.setAttribute('content', description);
}; */
