import React, { ReactNode } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { isLoggedVar } from '../../../infrastructure/apollo/cache';
import { apolloClient } from '../../../infrastructure/apollo/client';
import { GET_ALL_MENUS } from './queries';
import { NewLayoutComponent } from '../../components/NewLayout';
// import { getPermission } from '../../../infrastructure/utils/hooks/usePermission';
// import { NoAuthorize } from '../../../infrastructure/common/noAuthorize';

const LayoutC: React.FC<{ children: ReactNode }> = ({ children }) => {
  const history = useNavigate();

  const userCharge = JSON.parse(localStorage.getItem('ucId') || '{}');

  const { data: dataGetAllMenu, loading: loadingMenu } = useQuery(GET_ALL_MENUS, {
    fetchPolicy: 'cache-and-network',
  });

  const handleSession = () => {
    history('/');
    const colors = JSON.parse(localStorage.getItem('colorsTheme') || '{}');
    localStorage.clear();
    localStorage.setItem('colorsTheme', JSON.stringify(colors));
    apolloClient.cache.gc();
    isLoggedVar({ state: false, expired: false, code: '200', message: 'Ha cerrado sesión.' });
  };
  return (
    <NewLayoutComponent
      handleSession={handleSession}
      dataMenu={dataGetAllMenu?.menus}
      loadingMenu={loadingMenu}
      userCharge={userCharge}>
      {children}
    </NewLayoutComponent>
  );
};

export default LayoutC;
