export const dataMenus = [
  {
    id: 4,
    name: 'CRÉDITOS',
    route: '/creditos',
    subMenus: [
      {
        id: 42,
        name: 'Procesos',
        route: '/creditos',
        options: [
          {
            id: 421,
            name: 'Etapas',
            route: '/phases',
          },
        ],
      },
      {
        id: 43,
        name: 'Reportes',
        route: '/creditos',
        options: [
          {
            id: 431,
            name: 'Lista de créditos',
            route: '/creditos',
          },
        ],
      },
    ],
  },
];
